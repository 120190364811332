.poi-tracker-cont > * {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.poi-tracker-cont > div > div:first-child {
    min-width: 110px;
}

.checkbox-container {
    display: flex;
    flex-wrap: wrap;
}

.checkbox-item {
    color-scheme: light;
    margin-right: 10px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    user-select: none;
    cursor: pointer;
}

.checkbox-item input[type="checkbox"]:checked ~ span {
    color: var(--dark-yellow);
}

.checkbox-item input[type="checkbox"] {
    margin-left: 0;
    position: relative;
    cursor: pointer;
}

.poi-cont {
    position: relative;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.poi-tracker-frame-info {
    font-family: monospace;
    font-size: 13px;
    color: #ffffff;
    position: absolute;
    top: 3px;
    right: 3px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    font-weight: bold;
    pointer-events: auto;
    cursor: text;
    text-align: right;
}

@keyframes zoomInOut {
    0%, 100% { transform: scale(1); }
    50% { transform: scale(1.2); }
}

.poi-tracker-frame-info .attention-text {
    color: red;
    font-size: 16px;
    animation: zoomInOut 0.5s ease-in-out;
}

.poi-cross {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    pointer-events: none;
    font-size: 16px;
    color: #ffffff;
}

.poi-cross::before {
    content: '\2715';
}

.poi-rectangle {
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    border: 2px solid #9b9b9b;
    transform: translateX(-50%);
    pointer-events: none;
    background: transparent;
}

.dimming-left, .dimming-right {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

.dimming-left {
    left: 0;
}

.dimming-right {
    right: 0;
}

.poi-entity {
    position: absolute;
    border: 1px solid #fafa79;
    pointer-events: none;
    background: transparent;
}

.poi-entity::after {
    content: attr(data-info);
    position: absolute;
    white-space: pre;
    left: 100%;
    top: 0;
    margin-left: 1px;
    color: white;
    font-size: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
    /*padding: 1px;*/
    pointer-events: none;
}

.poi-entity-ball {
    border: 2px solid red;
    border-radius: 50%;
}

.poi-entity-keeper {
    border-color: #006fff
}

.poi-entity-referee {
    border-color: white
}

